<template>
    <header class="gst-event-info">
        <div class="gst-event-info__title">
            <span>{{ event.name }}</span>
            <span class="deal">
                {{ $t( '_common:terms.packageDeals' ) }}
            </span>
        </div>
        <div class="d-flex flex-column flex-md-row">
            <div class="gst-event-info__period d-flex flex-row justify-center align-center">
                <IconCalendar class="mr-2" />
                <span>{{ eventDate }}</span>
            </div>
            <div class="gst-event-info__location d-flex flex-row justify-center align-center">
                <IconLocation class="mr-2" />
                <VenueInfo :item="venueInfoLocation" />
            </div>
            <div class="gst-event-info__more-info d-flex flex-row justify-center align-center">
                <BaseButton
                    outlined
                    color="white"
                    class="gst-event-info__button"
                    @click="$emit('click-info')">
                    {{ $t('_common:buttons.moreInfo') }}
                </BaseButton>
            </div>
        </div>
    </header>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import VenueInfo from '@core/shared/components/containerInfo/VenueInfo';
    import IconLocation from '@tenant/app/assets/icons/location-outlined.svg';
    import IconCalendar from '@tenants/ticketmaster/app/assets/icons/calendar.svg';

    export default {
        name: 'EventInfo',
        components: {
            BaseButton,
            IconLocation,
            IconCalendar,
            VenueInfo
        },
        props: {
            event: {
                type: Object,
                required: true
            }
        },
        computed: {
            eventDate( ) {
                const { startDateTime, endDateTime, hasStartTime } = this.event;

                return this.$options.filters.eventPeriod( startDateTime, endDateTime, hasStartTime );
            },
            venueInfoLocation( ) {
                const { venueName, venueStreet, venueCity, venueState } = this.event;

                return {
                    name:          venueName,
                    street:        venueStreet,
                    city:          venueCity,
                    stateCode:     venueState
                };
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-info {
        color: theme-color( 'white' );

        .gst-event-info__title {
            line-height: theme-spacing( 10 ) !important;
            margin-top: 10vh;
            margin-bottom: theme-spacing( 4 );
            font-size: font-size( 'xxxxxxxl' );
            font-weight: font-weight( 'bold' );
            text-align: left;

            .deal {
                font-weight: font-weight( 'medium' );
            }
        }

        .gst-event-info__period,
        .gst-event-info__location {
            margin-right: theme-spacing( 8 );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );

            svg {
                height: 21px;
                width: 21px;

                .gst-svg-icon {
                    fill: theme-color( 'white' );
                }
            }
        }

        .gst-event-info__more-info {
            margin-right: theme-spacing( 8 );
        }

        .gst-event-info__button {
            height: 21px;
            padding: theme-spacing( 2 );
            border: 1px solid theme-color( 'white' );
            background-color: theme-color( 'transparent' ) !important;
            font-size: font-size( 'xxs' );
            border-radius: 20px;
            text-transform: unset !important;
        }
    }

    @include mobile-only {
        .gst-event-info {
            margin-top: 0;

            .gst-event-info__title {
                line-height: theme-spacing( 8 ) !important;
                margin-top: 0;
                margin-bottom: theme-spacing( 8 );
                font-size: font-size( 'xxxl' );
                text-align: center;
            }

            .gst-event-info__period,
            .gst-event-info__location {
                margin-bottom: theme-spacing( 3 );
                margin-right: theme-spacing( 0 );
            }

            .gst-event-info__more-info {
                margin-right: theme-spacing( 0 );
            }

            .gst-event-info__button {
                padding: theme-spacing( 2 );
                border: 1px solid theme-color( 'white' );
                background-color: theme-color( 'transparent' ) !important;
                font-size: font-size( 'xxs' );
                border-radius: 20px;
                text-transform: unset !important;
            }
        }
    }
</style>
