<template>
    <div ref="card" class="room-card-variant-3 d-flex flex-column" :style="{'min-height': `${cardMinHeight}px`}">
        <div v-if="displayRemainingInventory" class="room-card-variant-3__package-offer-inventory">
            {{ $t( '_common:terms.packageOfferInventory', { count: item.packageOfferInventory } ) }}
        </div>
        <BaseImage class="room-card-variant-3__image" :src="hotelPackage.hotelImage" :fallback-src="hotelPackage.hotelImageFallback" />
        <div class="room-card-variant-3__hotel-info d-flex flex-column flex-grow-1">
            <GuestsBadge v-if="showGuestBadge" :number-of-guests="hotelPackage.adultsCount" dark-mode class="room-card-variant-3__guests-badge" />
            <div class="hotel-name d-flex align-start justify-space-between">
                <p>
                    {{ hotelPackage.name }}
                </p>
                <StarRating :value="item.rating" class="d-flex star-rating align-center" />
            </div>
            <p class="distance-estimation">
                {{ item.distance }}
            </p>
            <div v-if="!isPreBundleFlow" class="d-flex align-center mb-3">
                <Bed class="mr-2" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="mb-0" v-html="packageHighlightForHotel"></p>
            </div>
            <div v-if="!isPreBundleFlow" class="d-flex align-center mb-3">
                <Tickets class="mr-2" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="mb-0" v-html="packageHighlightForTicket"></p>
            </div>
            <Highlights
                v-if="isPreBundleFlow"
                class="gst-highlights__toggle--white"
                :data="highlightsItemsToDisplay" 
                :hide-icons="hideIcons" 
                :number-of-items-to-display="isPreBundleFlow ? 3 : 2"
                :icon-item-width="22"
                :icon-item-height="22" 
                :show-all-external="expandedItem === item.id"
                is-external-toggle
                @expanded="setCurrentItem" />
            <div class="price-info mt-auto">
                <div class="d-flex align-center mt-4">
                    <p class="price-info__per-person_title">
                        {{ displayPersonPrice }}
                    </p>
                    <p class="price-info__per-person_subtitle ml-1">
                        {{ $t( '_common:terms.perPerson' ) }}
                    </p>
                </div>
                <div class="d-flex align-center mb-4">
                    <p class="price-info__per-package_title">
                        {{ hotelPackagePrice }}
                    </p>
                    <p class="price-info__per-package_subtitle ml-1">
                        {{ $t( '_common:terms.perPackage' ) }}
                    </p>
                </div>
                <BaseButton
                    :placeholder="$t('_common:buttons.selectPackage')"
                    class="select-package-button mb-3"
                    color="primary"
                    outlined
                    @click="$emit('select', hotelPackage )" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { divide as divideNumbers } from '@core/utils/numberUtils.js';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import offerConstants from '@core/utils/constants/offer';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import Bed from '@tenants/ticketmaster/app/assets/icons/bed.svg';
    import Tickets from '@tenants/ticketmaster/app/assets/icons/tickets.svg';
    import GuestsBadge from '@tenants/ticketmaster/app/components/badges/GuestsBadge.vue';
    import Highlights from '@tenants/ticketmaster/app/components/misc/Highlights/Highlights.vue';
    import HighLightsItemsMixin from '@tenants/ticketmaster/app/mixins/HighLightsItemsMixin';

    const NUMBER_TO_DISPLAY_PACKAGE_OFFER_INVENTORY = 10;
    const CARD_MIN_HEIGHT = 501;
    const CARD_MIN_HEIGHT_MOBILE = 389;

    export default {
        name: 'RoomCardVariant3',
        components: {
            Bed,
            Tickets,
            StarRating,
            BaseButton,
            BaseImage,
            Highlights,
            GuestsBadge
        },
        mixins: [
            HighLightsItemsMixin
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            hideIcons: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState( {
                expandedItem: state => state.packagesOffersV2.hotels.expandedItem,
                cardMinHeight: state => state.packagesOffersV2.hotels.minCardHeight,
            } ),
            ...mapGetters( {
                showGuestBadge: 'packagesOffersV2/hotels/hasDifferentGuestNumber'
            } ),
            hotelPackage( ) {
                return this.item;
            },
            hotelPackagePrice( ) {
                return this.$options.filters.currencyFilter( this.hotelPackage.price, this.hotelPackage.currency );
            },
            personPackagePrice( ) {
                return divideNumbers( this.hotelPackage.price, this.hotelPackage.adultsCount );
            },
            displayPersonPrice( ) {
                return this.$options.filters.currencyFilter( this.personPackagePrice, this.hotelPackage.currency );
            },
            estimatedWalkDistance( ) {
                return this.item.distance;
            },
            displayRemainingInventory() {
                return this.item.packageOfferInventory && this.item.packageOfferInventory < NUMBER_TO_DISPLAY_PACKAGE_OFFER_INVENTORY;
            },
            isPreBundleFlow( ) {
                return offerConstants.TYPES.PRE_BUNDLE_PACKAGE === this.item.ticketType;
            }
        },
        watch: {
            item: {
                handler() {
                    this.resizeCard( );
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            ...mapActions( {
                setExpandedItem : `packagesOffersV2/hotels/updateExpandedItem`,
                setCardMinHeight: `packagesOffersV2/hotels/updateCardMinHeight`
            } ),
            setCurrentItem( ) {
                if ( this.expandedItem === this.item.id ) {
                    this.setExpandedItem( null );
                } else {
                    this.setExpandedItem( this.item.id );
                }
            },
            resizeCard( ) {
                const card = this.$refs.card ? this.$refs.card.getBoundingClientRect( ) : null;
                const minHeight = this.$vuetify.breakpoint.mdAndUp ? CARD_MIN_HEIGHT : CARD_MIN_HEIGHT_MOBILE;
                if ( card ) {
                    if ( card.height > minHeight ) {
                        this.setCardMinHeight( card.height );
                    } else {
                        this.setCardMinHeight( minHeight );
                    }
                }
            },
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.room-card-variant-3 {
    position: relative;
    padding: theme-spacing( 1 );
    background-color: theme-color( 'primary' );

    .v-image {
        height: 245px;
        max-height: 245px;
    }

    .fallback-image {
        min-height: 245px;
    }

    .room-card-variant-3__package-offer-inventory {
        position: absolute;
        top: 10px;
        left: 10px;
        line-height: line-height( 'm' );
        padding: theme-spacing( 1 ) theme-spacing( 1 );
        background: theme-color( 'bonus-points-chip-bg-color' );
        color: theme-color( 'white' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'large' );
        text-transform: uppercase;
        border-radius: border-radius( 'xxs' );
        z-index: 1;
    }

    .room-card-variant-3__hotel-info {
        position: relative;
        padding: theme-spacing( 3, 2 );
        color: theme-color( 'white' );
        flex: 0.45;

        .hotel-name {
            margin-bottom: theme-spacing( 1 );

            p {
                line-height: line-height( 'xxxxl' );
                margin-bottom: theme-spacing( 0 );
                margin-right: theme-spacing( 1 );
                font-size: font-size( 'xxxl' );
                font-weight: font-weight( 'large' );
            }

            svg path {
                fill: theme-color( 'warning' );
            }
        }

        .room-card-variant-3__guests-badge {
            position: absolute;
            top: -28px;
            left: 0;
            border-radius: 0 6px 0 0;
        }

        .star-rating {
            padding-top: 3px;
        }

        .distance-estimation {
            line-height: line-height( 'm' );
            margin-bottom: theme-spacing( 3 );
            font-size: font-size( 'xxs' );
            opacity: 0.8;
        }

        svg path {
            fill: theme-color( 'white' );
        }

        .price-info {
            width: 100%;

            .price-info__per-person_title {
                line-height: line-height( 'xxxxxl' );
                margin-bottom: theme-spacing( 1 );
                font-size: font-size( 'xxxxl' );
                font-weight: font-weight( 'large' );
            }

            .price-info__per-person_subtitle {
                line-height: line-height( 'm' );
                margin-bottom: theme-spacing( 0 );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'large' );
            }

            .price-info__per-package_title {
                line-height: line-height( 'm' );
                margin-bottom: theme-spacing( 0 );
                font-size: font-size( 'l' );
            }

            .price-info__per-package_subtitle {
                line-height: line-height( 'm' );
                margin-bottom: theme-spacing( 0 );
                font-size: font-size( 's' );
            }

            .select-package-button {
                @include border-radius( 'xs' );

                width: 100%;
                padding: theme-spacing( 2, 3 );
                background: theme-color( 'white' );
                color: theme-color( 'primary' );
                font-weight: font-weight( 'large' );
                letter-spacing: normal;
                box-shadow: 0 1px 2px rgba( 0, 0, 0, 0.05 );
                text-transform: none;
            }

            .select-package-button:hover {
                background: rgba( theme-color-hex( 'white' ), 0.2 );
                color: theme-color( 'white' ) !important;
            }
        }
    }
}

.gst-highlights__toggle--white {
    a {
        padding-left: 30px;
        color: theme-color( 'white' ) !important;
        text-decoration: underline;
    }
}

@include mobile-only {
    .room-card-variant-3 {
        .v-image {
            height: 120px;
            max-height: 120px;
        }

        .fallback-image {
            height: 120px;
            min-height: 120px;
        }

        .room-card-variant-3__hotel-info {
            .hotel-name {
                p {
                    line-height: line-height( 'xxxl' );
                    font-size: font-size( 'l' );
                }
            }

            .price-info {
                p:nth-child(1) {
                    line-height: line-height( 'xxxl' );
                    font-size: font-size( 'xxl' );
                }
            }
        }
    }
}
</style>
