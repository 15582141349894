import {
    getDescriptionForHotel as getPackageHighlightDescriptionForHotel,
    getDescriptionForTicket as getPackageHighlightDescriptionForTicket
} from '@tenants/ticketmaster/app/utils/packageHighlightsUtils';
import { sortAndCombineLists as sortAndCombineHighlights } from '@tenants/ticketmaster/app/utils/highlightsUtils';
import highlightTypeConstants from '@tenants/ticketmaster/app/utils/constants/highlights';

export default {
    computed: {
        packageHighlightForHotel( ) {
            return getPackageHighlightDescriptionForHotel( this.item.packageHighlights )
              || this.$t( '_common:terms.oneNightStayInDoubleRoom' );
        },
        packageHighlightForTicket( ) {
            return getPackageHighlightDescriptionForTicket( this.item.packageHighlights )
                || this.$t( '_common:terms.twoStandardTickets' );
        },
        highlightsItems( ) {
            return [
                {
                    type: highlightTypeConstants.TYPES.HOTEL,
                    description: this.packageHighlightForHotel,
                    subtitle: this.displayPeriodHighlight && this.item.stayPeriod
                },
                !this.displayHotelHighlightOnly && {
                    type: highlightTypeConstants.TYPES.TICKET,
                    description: this.packageHighlightForTicket
                }
            ];
        },
        highlightsItemsToDisplay( ) {
            let sortedAndCombinedHighlights = sortAndCombineHighlights( this.item.packageHighlights, this.highlightsItems );
            return this.displayHotelHighlightOnly ? [ sortedAndCombinedHighlights[0] ] : sortedAndCombinedHighlights;
        },
    }
};