<template>
    <div class="gst-guests-badge d-flex align-center" :class="{ 'gst-guests-badge--dark-mode': darkMode }">
        <BaseIcon symbol-id="icons--user" /> x{{ numberOfGuests }}
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'GuestsBadge',
        components: {
            BaseIcon
        },
        props: {
            numberOfGuests: {
                type: Number,
                default: 2
            },
            darkMode: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";

.gst-guests-badge {
    padding: 4px 8px 4px 4px;
    background: theme-color( 'quinary' );
    color: theme-color( 'quaternary' );
    font-size: font-size( 's' );
    font-weight: font-weight( 'large' );
    border-radius: border-radius( 'xs' );
    gap: 2px;

    ::v-deep .gst-svg-icon {
        stroke: theme-color( 'quaternary' );
        fill: theme-color( 'transparent' );
    }
}

.gst-guests-badge--dark-mode {
    background: theme-color( 'primary' );
    color: theme-color( 'white' );

    ::v-deep .gst-svg-icon {
        stroke: theme-color( 'white' );
    }
}
</style>
