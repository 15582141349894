<template>
    <div class="gst-the-event-package-offer">
        <DataLoading v-if="loading" class="pa-10" />
        <EventPackageOffer
            v-else-if="packageDetails"
            :description="getPackageDetailsByEventId( )"
            :event="event"
            :package-details="packageDetails"
            :hotels="hotels"
            :loading-hotels="loadingHotels"
            :has-package-offers="!isEventPackageOffersNotAvailable" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import EntityNotFoundRedirect404 from '@core/mixins/EntityNotFoundRedirect404';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import EventPackageOffer from './TheEventPackageOffer/EventPackageOffer.vue';
    import HealthCheckConfirmationModal from './TheEventPackageOffer/HealthCheckConfirmationModal';

    export default {
        name: 'TheEventPackageOffer',
        components: {
            DataLoading,
            EventPackageOffer
        },
        mixins: [
            EntityNotFoundRedirect404,
            ViewDocumentTitleMixin
        ],
        data( ) {
            return {
                loading: { },
                event: { },
                packageDetails: null,
                isPackageFlow: false,
                loadingHotels: { },
                hotels: [ ],
            };
        },
        computed: {
            ...mapGetters( {
                getAvailable                    : `packagesOffersV2/getAvailable`,
                getPackageDetailsByEventId      : `packagesOffersV2/getDetailsByEventId`,
            } ),
            routeId( ) {
                return this.$route.params.id;
            },
            routeEventParams( ) {
                return this.$route.params.event;
            },
            itemId( ) {
                return this.event.id;
            },
            isEvent( ) {
                return !!this.event.id;
            },
            isUsingLegacyRoute() {
                return this.$route.name === 'tm-eventPackageOffer';
            },
            isEventPackageOffersNotAvailable( ) {
                return !this.isPackageFlow || !this.hotels.length;
            },
            hasHealthCheck( ) {
                return !!this.event.healthCheck;
            },
            needToShowConfirmationHealthCheck( ) {
                return this.hasHealthCheck && !this.isEventPackageOffersNotAvailable;
            },
            eventHasTicketsAvailable() {
                // HOTFIX TMH-1047 - remove all the checks
                return true;
            },
            isEventAvailable( ) {
                return this.eventHasTicketsAvailable;
            }
        },
        methods: {
            ...mapActions( {
                loadOne                         : `events/one`,
                loadOneByLegacy                 : `events/oneByLegacy`,
                oneByEventId                    : `packagesOffersV2/oneByEventId`,
                getHotelsByEventId              : `packagesOffersV2/hotels/getByEventId`,
                setHamburgerMenuVisible         : 'appState/setHamburgerMenuVisible',
                updateLanguagesForEvent: 'languages/updateLanguagesForEvent'
            } ),
            async loadEvent( ) {
                const loadEventData = async ( ) => {
                    if ( this.isUsingLegacyRoute ) {
                        return this.loadOneByLegacy( { id: this.routeId } );
                    }

                    return this.loadOne( this.routeId );
                };
                const responseEvent = await loadEventData( );

                if ( !responseEvent ) {
                    this._goToNotFoundPage( );
                    return;
                }

                this.event = responseEvent;

                return this.event;
            },
            async loadEventFromRoute( ) {
                if ( this.$route.params.event ) {
                    this.event = this.$route.params.event;
                    return this.event;
                }

                return false;
            },
            async loadEventData( ) {
                if ( !this._destroyed ) {
                    await this.loadEventFromRoute( ) || await this.loadEvent( );
                }
                if ( !this._destroyed && this.itemId ) {
                    this.updateLanguagesForEvent( this.event.legacyEventId );
                    this.packageDetails =  await this.oneByEventId( { eventId: this.itemId } );
                    this.isPackageFlow = this.getAvailable( this.itemId );
                }
            },
            async loadHotels( ) {
                if ( !this.destroyed && this.isEventAvailable ) {
                    const responseData = await this.getHotelsByEventId( { eventId: this.itemId } );
                    this.hotels = responseData.hotels || [];
                    this.isPackageFlow = !!this.hotels.length;
                }
            },
            openHealthCheckConfirmationModal( ) {
                const { healthCheck } = this.event;
                this.$modal.show(
                    HealthCheckConfirmationModal,
                    {
                        healthCheck
                    },
                    {
                        'no-click-animation': true,
                        scrollable: true,
                        fullscreen: false,
                        persistent: true,
                        'hide-overlay': false,
                        'max-width': 858,
                        'content-class': 'gst-v-dialog',
                    }
                );
            },
        },
        async mounted( ) {
            this.setHamburgerMenuVisible( false );
            this.loading = true;
            await this.loadEventData( );
            this.loading = false;
            this.loadingHotels = true;
            if ( this.isPackageFlow ) {
                this.updateDocumentTitle( this.$t( 'main:viewsTitle.theEventPackageOffer', { package: this.event.name } ) );
                await this.loadHotels( );
            }
            this.loadingHotels = false;
            if ( this.needToShowConfirmationHealthCheck ) {
                this.openHealthCheckConfirmationModal( );
            }

            if ( this.event.id && this.isEventAvailable && this.isEventPackageOffersNotAvailable ) {
                this.$router.push(
                    {
                        name: 'event',
                        params: {
                            id: this.event.id,
                            event: this.event,
                            packageDetails: this.packageDetails
                        }
                    },
                );
            }
        }
    };
</script>


<style scoped lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    $img-bg-container-bg-color: #1F262D;

    .gst-the-event-package-offer {
        height: calc( 100vh - 50px );
        background-color: $img-bg-container-bg-color;
    }
</style>
