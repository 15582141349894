<template>
    <div class="gst-highlight-item d-flex align-center mb-2">
        <BaseIcon v-if="showIcon" :symbol-id="hasValidIcon ? item.icon : 'icons--highlights--check'" class="mr-2 flex-shrink-0" :width="iconItemWidth" :height="iconItemHeight" />
        <div>
            <div v-if="item.description">
                {{ item.description }}
            </div>
            <div v-if="item.subtitle" class="tertiary--text">
                {{ item.subtitle }}
            </div>
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import highlightTypeConstants from '@tenants/ticketmaster/app/utils/constants/highlights';

    export default {
        name: 'HighlightItem',
        components: {
            BaseIcon
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            showIcon: {
                type: Boolean,
                default: false
            },
            iconItemWidth: {
                type: Number,
                default: null
            },
            iconItemHeight: {
                type: Number,
                default: null
            }
        },
        computed: {
            hasValidIcon() {
                //checks if this.item.type exists as a value in the TYPES obj
                return Object.values( highlightTypeConstants.TYPES ).includes( this.item.type );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-highlight-item {
    svg {
        path {
            fill: theme-color( 'tertiary' );
        }
    }
}
</style>