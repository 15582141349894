<template>
    <div class="gst-background-image"
        :class="{
            'u-height-100': $vuetify.breakpoint.mdAndUp
        }">
        <BaseImage
            :src="src"
            transition="fade-transition"
            cover
            class="gst-background-image__picture u-height-100" />
        <div class="gst-background-image__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import BaseImage from '@core/shared/components/misc/BaseImage';

    export default {
        name: 'ImageBackgroundContainer',
        components: {
            BaseImage
        },
        props: {
            src: {
                type: String,
                default: ''
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    $img-bg-container-bg-color: #1F262D;

    .gst-background-image {
        position: relative;
        width: 100%;
        min-height: 100%;

        .gst-background-image__picture {
            width: 50%;
            background-color: $img-bg-container-bg-color;
        }

        .gst-background-image__content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .gst-background-image__content::-webkit-scrollbar {
            display: none;
        }

        &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient( 90deg, rgba( 31, 38, 45, 0 ) 0%, $img-bg-container-bg-color 48%, $img-bg-container-bg-color 100% );
            content: "";
        }

        @include mobile-only {
            .gst-background-image__picture {
                height: 250px;
                width: 100%;

                .v-image__image {
                    background-position: top center !important;
                }
            }

            .gst-background-image__content {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                overflow: unset;
            }

            &::after {
                background: linear-gradient( 180deg, rgba( 31, 38, 45, 0 ) 0%, $img-bg-container-bg-color 240px, $img-bg-container-bg-color 100% );
            }
        }
    }

</style>
