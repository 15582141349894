export const IMAGE_SIZE = {
    'THUMBNAIL': 117,
    'MEDIUM': 320,
    'LARGE': 800,
    'ORIGINAL': 'ORIGINAL'
};

export default {
    IMAGE_SIZE
};
