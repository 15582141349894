<template>
    <div class="gst-event-package-offer-not-available">
        <div class="gst-event-package-offer-not-available__body d-flex align-center justify-center">
            <component :is="componentWarning" :event="event" />
        </div>
    </div>
</template>

<script>
    import eventConstants from '@core/utils/constants/event';
    import {
        isPast as eventUtilsIsPast,
        isSaleDatesInFuture as eventUtilsIsSaleDatesInFuture
    } from '@core/utils/eventUtils';
    import EventStatusCancelledWarning from '@tenant/app/static/TheEvent/EventNotAvailable/EventStatusCancelledWarning';
    import EventStateInactiveWarning from './EventNotAvailable/EventStateInactiveWarning';
    import EventStatePastWarning from './EventNotAvailable/EventStatePastWarning';
    import EventTicketStatusNotAvailableWarning from './EventNotAvailable/EventTicketStatusNotAvailableWarning';
    import EventStateOnSaleDateFutureWarning from './EventNotAvailable/EventStateOnSaleDateFutureWarning';
    import EventStateNoDateWarning from './EventNotAvailable/EventStateNoDateWarning';
    import EventPackageOffersNotAvailableWarning from './EventNotAvailable/EventPackageOffersNotAvailableWarning';
    import EventPackageOffersSoldOutWarning from './EventNotAvailable/EventPackageOffersSoldOutWarning';

    export default {
        name: 'EventNotAvailable',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.eventNotAvailable'
        },
        components: {
            /* eslint-disable vue/no-unused-components */
            EventStatusCancelledWarning,
            EventStateInactiveWarning,
            EventStatePastWarning,
            EventStateOnSaleDateFutureWarning,
            EventStateNoDateWarning,
            EventPackageOffersNotAvailableWarning,
            EventPackageOffersSoldOutWarning
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            isPackageOffersSoldOut: {
                type: Boolean,
                default: false
            },
            isPackageOffersNotAvailable: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            componentWarning( ) {
                const { ticketsStatus, eventStatus, startDateTimeAt, onSaleStartDateTimeAt, active } = this.event;
                const { isPackageOffersNotAvailable, isPackageOffersSoldOut } = this;

                if ( !active ) {
                    return EventStateInactiveWarning;
                } else if ( eventStatus === eventConstants.EVENT_STATUS.CANCELLED ) {
                    return EventStatusCancelledWarning;
                } else if ( eventUtilsIsPast( this.event ) ) {
                    return EventStatePastWarning;
                } else if ( ticketsStatus === eventConstants.TICKETS_STATUS.NOT_AVAILABLE ) {
                    return EventTicketStatusNotAvailableWarning;
                } else if ( eventUtilsIsSaleDatesInFuture( this.event ) ) {
                    return EventStateOnSaleDateFutureWarning;
                } else if ( !startDateTimeAt || !onSaleStartDateTimeAt ) {
                    return EventStateNoDateWarning;
                } else if ( isPackageOffersSoldOut ) {
                    return EventPackageOffersSoldOutWarning;
                } else if ( isPackageOffersNotAvailable ) {
                    return EventPackageOffersNotAvailableWarning;
                } else {
                    return null;
                }
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-package-offer-not-available {
        .gst-event-package-offer-not-available__body {
            line-height: font-size( 'xxxxl' );
            padding: theme-spacing( 4 );
            border: 1px solid rgba( theme-color-hex( 'white' ), 0.5 );
            margin-top: 188px;
            margin-bottom: theme-spacing( 4 );
            background: transparent;
            color: theme-color( 'white' );
            font-size: font-size( 'xxxxl' );
            font-weight: font-weight( 'large' );
            border-radius: border-radius( 'xs' );
            max-width: 614px;
            text-align: center;

            h5 {
                line-height: font-size( 'xxxxl' );
                font-size: font-size( 'xxxxl' );
                font-weight: font-weight( 'large' );
            }

            .gst-event-package-offer-not-available__content,
            .gst-event-not-available__content {
                line-height: font-size( 'xxxl' );
                margin-top: theme-spacing( 4 );
                margin-bottom: theme-spacing( 0 );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
            }
        }
    }

    @include mobile-only {
        .gst-event-package-offer-not-available {
            .gst-event-package-offer-not-available__body {
                line-height: font-size( 'xxxl' );
                margin-top: 40px;
                font-size: font-size( 'xxxl' );
                max-width: 100%;

                h5 {
                    font-size: font-size( 'xxxl' );
                }

                .gst-event-package-offer-not-available__content {
                    line-height: font-size( 'xl' );
                    font-size: font-size( 's' );
                    font-weight: font-weight( 'regular' );
                }
            }
        }
    }
</style>