<template>
    <div class="gst-star-rating">
        <div v-for="i in maxStars" :key="i" class="d-inline">
            <slot v-if="getStarType( i ) === 'full-star'" name="full">
                <BaseIcon symbol-id="icons--star_full" />
            </slot>
            <slot v-if="getStarType( i ) === 'half-star'" name="half">
                <BaseIcon symbol-id="icons--star_half" />
            </slot>
            <slot v-if="getStarType( i ) === 'empty-star'" name="empty">
                <BaseIcon symbol-id="icons--star_empty" />
            </slot>
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        components: {
            BaseIcon
        },
        props: {
            value: {
                type: Number,
                default: 0,
            },
            length: {
                type: Number,
                default: 5
            },
            rateType: {
                type: String,
                default: 'star'
            }
        },
        computed: {
            maxStars( ) {
                return Math.max( Math.ceil( this.value ), this.length );
            }
        },
        methods: {
            getStarType( key ) {
                if( key <=  this.value  ) {
                    return 'full-star';
                }
                else if ( key > Math.ceil( this.value ) ) {
                    return 'empty-star';
                }
                else if ( key === Math.ceil( this.value ) && ( key !== Number.isInteger( this.value ) ) ) {
                    return 'half-star';
                }
            },
        }
    };
</script>

<style lang="scss" scoped>
    .gst-star-rating {
        min-width: fit-content !important;
    }
</style>