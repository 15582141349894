<template>
    <div class="gst-event-package-offer u-height-100">
        <ImageBackgroundContainer
            :src="backgroundImageUrl">
            <div class="gst-event-package-offer__content">
                <EventInfo :event="event" class="gst-event-package-offer__info" @click-info="openEventInfoModal" />
                <DataLoading v-if="loadingHotels" class="my-5 py-5 gst-event-package-offer__content-loader" />
                <template
                    v-else-if="isEventNotAvailable">
                    <div class="gst-event-package-offer__not-available d-flex flex-column">
                        <!-- eslint-disable-next-line vue/no-v-html  -->
                        <span v-if="descriptionContent.contentTop" v-html="descriptionContent.contentTop"></span>
                        <!-- eslint-disable-next-line vue/no-v-html  -->
                        <span v-if="descriptionContent.contentBottom" v-html="descriptionContent.contentBottom"></span>
                        <EventNotAvailable
                            :event="event"
                            :is-package-offers-not-available="isEventPackageOffersNotAvailable"
                            :is-package-offers-sold-out="isEventPackageOffersSoldOut" />
                    </div>
                </template>
                <template v-else>
                    <Swiper
                        :items="hotels"
                        :card-width="cardsProps.width"
                        :navigation="$vuetify.breakpoint.mdAndUp"
                        :center="$vuetify.breakpoint.smAndDown"
                        :scroll-left-gutter="$vuetify.breakpoint.smAndDown ? 0 : 4"
                        class="gst-event-package-offer__swiper">
                        <template slot="headerName">
                            <!-- eslint-disable-next-line vue/no-v-html  -->
                            <span v-if="descriptionContent.contentTop" v-html="descriptionContent.contentTop"></span>
                        </template>
                        <template slot="item" slot-scope="slotProps">
                            <RoomCardVariant3
                                :item="mapItemForCard( slotProps.item )"
                                class="mx-1"
                                @select="selectHotelReservationRoom( $event )" />
                        </template>
                    </Swiper>
                    <div class="d-flex flex-wrap justify-center justify-md-start">
                        <BaseButton
                            :placeholder="$t('_common:buttons.viewAllPackages')"
                            class="gst-event-package-offer__view-all-button"
                            color="white"
                            outlined
                            @click="onSeeAllPackagesClickDo" />
                        <div class="u-width-100 d-flex justify-center justify-md-start">
                            <!-- eslint-disable-next-line vue/no-v-html  -->
                            <span v-if="descriptionContent.contentBottom" class="gst-event-package-offer__description-bottom" v-html="descriptionContent.contentBottom"></span>
                        </div>
                    </div>
                </template>
            </div>
        </ImageBackgroundContainer>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';
    import RoomCardVariant3 from '@tenants/ticketmaster/app/modules/hotel/components/cards/RoomCardVariant3.vue';
    import Swiper from '@tenants/ticketmaster/app/components/sliders/Swiper.vue';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import EventInfo from './components/EventInfo.vue';
    import ImageBackgroundContainer from './components/ImageBackgroundContainer';
    import EventNotAvailable from './components/EventNotAvailable';

    export default {
        name: 'EventPackageOffer',
        components: {
            ImageBackgroundContainer,
            EventInfo,
            Swiper,
            RoomCardVariant3,
            DataLoading,
            BaseButton,
            EventNotAvailable,
        },
        props: {
            description: {
                type: String,
                default: ''
            },
            event: {
                type: Object,
                required: true,
            },
            loadingHotels: {
                type: Boolean,
                required: true,
            },
            hotels: {
                type: Array,
                required: true,
            },
            packageDetails: {
                type: Object,
                required: true
            },
            hasPackageOffers: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            backgroundImageUrl() {
                return this.packageDetails.data.packageDetails.imageUrl ?  this.packageDetails.data.packageDetails.imageUrl : this.event.imageUrl;
            },
            cardsProps( ) {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    return {
                        width: 375
                    };
                }
                return {
                    width: 296
                };
            },
            isEventPackageOffersNotAvailable() {
                return !this.hasPackageOffers && !this.description;
            },
            isEventPackageOffersSoldOut() {
                return !!( !this.hasPackageOffers && this.description );
            },
            /**
             * TODO but this is need to be reviewed
             *
             * TMH-1047 remove the logic.
             *
             */
            isEventNotAvailable( ) {
                return this.isEventPackageOffersNotAvailable || this.isEventPackageOffersSoldOut;
            },
            descriptionContent() {
                const htmlRegex = /<[a-z][\s\S]*>/i;
                const isHtml  = htmlRegex.test( this.description );

                if ( isHtml ) {
                    let doc = new DOMParser().parseFromString( this.description, 'text/html' );
                    const firstTagElement = doc.body.children[0];
                    const lastTagElement = doc.body.children[doc.body.children.length -1];
                    firstTagElement.style.marginBottom = 0;
                    lastTagElement.style.marginBottom = 0;
                    const firstTagElementContent = firstTagElement.outerHTML;
                    const hasMultipleTagElements = doc.body.children.length > 1;

                    if ( hasMultipleTagElements ) {
                        const restTagElementsContent = Object.values( doc.body.children )
                            .filter( ( item, index ) => index > 0 )
                            .map( item => item.outerHTML )
                            .join( '' );
                        return {
                            contentTop: firstTagElementContent,
                            contentBottom: restTagElementsContent
                        };
                    } else {
                        return {
                            contentTop: firstTagElementContent,
                            contentBottom: null
                        };
                    }
                }

                return {
                    contentTop: this.description
                };
            }
        },
        methods: {
            openEventInfoModal( ) {
                this.$router.push( {
                    name: 'eventInfo',
                    params: {
                        id: this.event.id
                    }
                } );
            },
            onSeeAllPackagesClickDo( ) {
                this.$router.push(
                    {
                        name: 'event',
                        params: {
                            id: this.event.id,
                            event: this.event,
                            packageDetails: this.packageDetails
                        }
                    },
                );
            },
            selectHotelReservationRoom( hotelReservationRoom ) {
                this.$router.push(
                    {
                        name: 'event',
                        params: {
                            id: this.event.id,
                            selectedHotelReservationRoom: hotelReservationRoom,
                            event: this.event,
                            packageDetails: this.packageDetails
                        }
                    },
                );
            },
            getRating( item ) {
                const { awards } = item;

                if ( awards.length && awards[ 0 ].rating ) {
                    return awards[ 0 ].rating;
                }

                return 0;
            },
            mapItemForCard( item ) {
                const event = this.event;
                const rate = item.selectedRoom.selectedRate;
                const packageOffer = rate.packageOffer;
                const distance = this.$options.filters.distanceToVenue(
                    item.distance,
                    getDistanceUnitOfMeasureForCountry ( event.venueCountry )
                );
                const packageOfferInventory = item.selectedRoom.selectedRate.packageOffer.inventory;

                return {
                    id: packageOffer.id,
                    currency: rate.currency,
                    hotelImage: getImageByWidth( IMAGE_SIZE.LARGE, item.image ),
                    hotelImageFallback: getImageByWidth( IMAGE_SIZE.ORIGINAL, item.image ),
                    name: item.name,
                    price: packageOffer.basePrice,
                    rating: this.getRating( item ),
                    distance,
                    packageOfferId: packageOffer.id,
                    ticketType: packageOffer.ticketType,
                    packageHighlights: item.packageHighlights,
                    packageOfferInventory,
                    adultsCount: item.selectedRoom.accommodation.guests.adultsCount
                };
            }
        }
    };
</script>


<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-package-offer {
        color: theme-color( 'white' );

        .gst-event-package-offer__content {
            display: flex;
            min-height: 100%;
            flex-direction: column;
            justify-content: center;

            .gst-event-package-offer__content-loader {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 632px;
            }
        }

        .gst-event-package-offer__info {
            margin-left: 33%;
        }

        .gst-event-package-offer__not-available {
            margin-top: theme-spacing( 6 );
            margin-left: 33%;
        }

        .gst-event-package-offer__swiper {
            margin-top: theme-spacing( 6 );

            .gst-swiper__header {
                margin-bottom: theme-spacing( 10 );
                margin-left: 33%;

                .gst-swiper__header-name {
                    margin-right: theme-spacing( 2 );
                    font-size: font-size( 'l' );
                    font-weight: font-weight( 'regular' );
                    max-width: 1038px;
                }
            }

            .gst-swiper__content {
                padding-left: 33%;
                margin-left: theme-spacing( n1 );
            }
        }

        .gst-event-package-offer__view-all-button {
            margin-top: theme-spacing( 6 );
            margin-bottom: theme-spacing( 6 );
            font-size: font-size( 's' );
            margin-left: 33%;
            text-transform: unset;
        }

        .gst-event-package-offer__view-all-button:hover {
            background-color: rgba( theme-color-hex( 'white' ), 0.2 );
        }

        .gst-event-package-offer__description-bottom {
            margin-bottom: theme-spacing( 10 );
            margin-right: theme-spacing( 2 );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'regular' );
            margin-left: 33%;
            max-width: 1038px;
        }
    }

    @media screen and (min-width: 960px) and (max-height: 800px) {
        .gst-event-package-offer {
            height: calc( 100% + 25vh );
        }

        .gst-event-package-offer__content {
            min-height: calc( 100% - 50px );

            .gst-event-package-offer__content-loader {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: unset;
            }
        }
    }

    @media screen and (min-width: 700px) and (max-width: 959px) {
        .gst-event-package-offer {
            .gst-event-package-offer__swiper {
                .gst-swiper__header {
                    margin: theme-spacing( 0 );
                    justify-content: center;

                    .gst-swiper__header-name {
                        max-width: 460px;
                    }
                }
            }

            .gst-event-package-offer__description-bottom {
                margin: theme-spacing( 0 );
                max-width: 460px;
            }
        }
    }

    @include mobile-only {
        .gst-event-package-offer {
            .gst-event-package-offer__content {
                height: 100%;
            }

            .gst-event-package-offer__info {
                margin: theme-spacing( 0, 4 );
            }

            .gst-event-package-offer__not-available {
                margin: theme-spacing( 4 );
                text-align: center;
            }

            .gst-event-package-offer__swiper {
                .gst-swiper__header {
                    margin-right: theme-spacing( 6 );
                    margin-left: theme-spacing( 6 );

                    .gst-swiper__header-name {
                        font-size: font-size( 'm' );
                        text-align: left;
                    }
                }

                .gst-swiper__content {
                    padding-left: 0;
                }
            }

            .gst-event-package-offer__view-all-button {
                margin-top: theme-spacing( 6 );
                margin-left: 0;
            }

            .gst-event-package-offer__description-bottom {
                margin-right: theme-spacing( 6 );
                font-size: font-size( 'm' );
                margin-left: theme-spacing( 6 );
                text-align: left;
            }
        }
    }
</style>
