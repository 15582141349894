import highlightTypeConstants from '@tenants/ticketmaster/app/utils/constants/highlights';

function sortList( data ) {
    return [ ...data ].sort( ( a, b ) => {
        if ( a.type === highlightTypeConstants.TYPES.HOTEL ) {
            return -1;
        } else if ( a.type === highlightTypeConstants.TYPES.TICKET ) {
            return b.type === highlightTypeConstants.TYPES.HOTEL ? 1 : -1;
        } else {
            return b.type === highlightTypeConstants.TYPES.HOTEL || b.type === highlightTypeConstants.TYPES.TICKET ? 1 : -1;
        }
    } );
}
function combineLists( initList, addList ) {
    let resultList = [ ...initList ];
  
    addList.forEach( ( item ) => {
        let matchingIndex = resultList.findIndex( ( obj ) => obj.type === item.type );
  
        if ( matchingIndex === -1 ) {
            resultList.push( item );
        } else {
            let matchingObj = resultList[matchingIndex];
            let mergedObj = { ...matchingObj, ...item };
            resultList[matchingIndex] = mergedObj;
        }
    } );
  
    return resultList;
}

export function normalizeList ( data ) {
    return data.map( ( item ) => {
        return {
            ...item,
            icon: item.type ? `icons--highlights--${item.type.toLowerCase()}` : undefined
        };
    } );
}

export function sortAndCombineLists ( lst1, lst2 ) {
    return sortList( combineLists( lst1, lst2 ) );
}