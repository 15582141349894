<template>
    <div>
        <h5 class="gst-event-package-offer-not-available__title">
            {{ $t( 'title' ) }}
        </h5>
        <p class="gst-event-package-offer-not-available__content">
            {{ $t( 'message' ) }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'EventStatusPastWarningCard',
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'messages.eventState.past'
        }
    };
</script>