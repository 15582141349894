export default {
    TYPES: {
        ALCOHOL: 'ALCOHOL',
        BOOK: 'BOOK',
        BOOK_SIGNING: 'BOOK_SIGNING',
        CALENDAR: 'CALENDAR',
        CHARITY: 'CHARITY',
        CHECK: 'CHECK',
        CLUB: 'CLUB',
        COLLECTABLE_TICKET: 'COLLECTABLE_TICKET',
        COST: 'COST',
        EXHIBIT: 'EXHIBIT',
        FESTIVAL: 'FESTIVAL',
        FOOD_AND_BEV: 'FOOD_AND_BEV',
        GIFT_CARD: 'GIFT_CARD',
        HOTEL: 'HOTEL',
        LOCATION: 'LOCATION',
        MERCH: 'MERCH',
        MUSIC: 'MUSIC',
        RESTAURANT: 'RESTAURANT',
        SIGNED_POSTER: 'SIGNED_POSTER',
        SPECIAL_ENTRY: 'SPECIAL_ENTRY',
        TICKET: 'TICKET',
        TIME: 'TIME',
        TRANSPORTATION: 'TRANSPORTATION',
        VIP: 'VIP',
        WELLNESS: 'WELLNESS'
    } 
}; 