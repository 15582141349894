<template>
    <div class="gst-highlights">
        <div v-for="( item, index ) in itemsToDisplay" :key="index">
            <HighlightItem :item="item" :show-icon="!hideIcons" :icon-item-width="iconItemWidth" :icon-item-height="iconItemHeight" />
        </div>
        <a v-if="normalizedData.length > numberOfItemsToDisplay" 
            class="gst-highlights__toggle"
            @click="toggleShowAll"
            @keypress.enter="toggleShowAll">
            {{ toggleText }}
        </a>
    </div>
</template>

<script>
    import { normalizeList as normalizeHighlightsList } from '@tenants/ticketmaster/app/utils/highlightsUtils';
    import HighlightItem from './HighlightItem.vue';

    export default {
        name: 'Highlights',
        components: {
            HighlightItem
        },
        emits: [ 'expanded' ],
        props: {
            data: {
                type: Array,
                required: true
            },
            numberOfItemsToDisplay: {
                type: Number,
                default: 2
            },
            hideIcons: {
                type: Boolean,
                default: false
            },
            showAllExternal: {
                type: Boolean,
                default: false
            },
            isExternalToggle: {
                type: Boolean,
                default: false
            },
            iconItemWidth: {
                type: Number,
                default: null
            },
            iconItemHeight: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                showAllInternal: false
            };
        },
        computed: {
            normalizedData() {
                return normalizeHighlightsList( this.data );
            },
            toggleText() {
                return !this.showAll ? this.$t( '_common:terms.plusMore', { count: this.normalizedData.length - this.numberOfItemsToDisplay } ) :
                    this.$t( '_common:terms.minusLess' );
            },
            itemsToDisplay() {
                return this.showAll ? this.normalizedData : this.normalizedData.slice( 0, this.numberOfItemsToDisplay );
            },
            showAll( ) {
                return this.isExternalToggle ? this.showAllExternal : this.showAllInternal;
            }

        },
        methods: {
            toggleShowAll( event ) {
                event.stopPropagation( );
                this.$emit( 'expanded' );
                if ( !this.isExternalToggle ) {
                    this.showAllInternal = !this.showAllInternal;
                }
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-highlights__toggle {
    color: theme-color( 'primary' );
    font-size: font-size( 's' );
    font-weight: font-weight( 'large' );
}

</style>
