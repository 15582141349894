<template>
    <div v-resize="setSwiperPaddingDebounced" class="gst-swiper">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <template v-else>
            <slot name="header">
                <div class="gst-swiper__header d-flex flex-row">
                    <div class="gst-swiper__header-name flex-grow-1">
                        <slot name="headerName"></slot>
                    </div>
                    <v-flex v-if="showNavigationButtons" d-flex flex-row shrink class="gst-swiper__navigation align-end mr-2">
                        <BaseButton ref="previousButton" :disabled="isReachStart" icon class="mr-6" @click="goToPreviousSlides">
                            <BaseIcon symbol-id="icons--chevron_left_round" />
                        </BaseButton>
                        <BaseButton ref="nextButton" :disabled="isReachEnd" :icon="true" @click="goToNextSlides">
                            <BaseIcon symbol-id="icons--chevron_right_round" />
                        </BaseButton>
                    </v-flex>
                </div>
            </slot>
            <div
                ref="swiper"
                d-flex
                flex-row
                class="gst-swiper__content u-hide-scrollbar d-flex flex-row"
                :style="{ ...swiperPaddings }"
                @scroll="setSwiperNavigationDebounced"
                @resize="setSwiperNavigationDebounced">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="flex-shrink-0"
                    :style="{
                        width: cardWidth+'px',
                    }">
                    <slot name="item" :item="item">
                    </slot>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'Swiper',
        components: {
            DataLoading,
            BaseButton,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.similarAttractions'
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: null
            },
            cardWidth: {
                type: Number,
                default: 123
            },
            cardsToScroll: {
                type: Number,
                default: 1
            },
            cardsPerScreen: {
                type: String,
                default: 'auto'
            },
            navigation: {
                type: Boolean,
                default: true
            },
            center: {
                type: Boolean,
                default: true
            },
            scrollLeftGutter: {
                type: Number,
                default: 4
            }
        },
        data() {
            return {
                isReachStart: true,
                isReachEnd: false,
                swiperPaddings: { }
            };
        },
        computed: {
            showNavigationButtons( ) {
                return this.navigation && !( this.isReachStart && this.isReachEnd );
            },
            cardWidthForScroll( ) {
                return this.cardsToScroll * this.cardWidth;
            }
        },
        watch: {
            'center': function( ) {
                this.setSwiperPaddingDebounced( );
            },
        },
        methods: {
            goToPreviousSlides( ) {
                const swiperEl = this.$refs.swiper;
                const cardWidthForScroll = this.cardWidthForScroll;
                const scrollLeft = swiperEl.scrollLeft;

                const scrollUntilPreviousInCards = ( scrollLeft / cardWidthForScroll ) - Math.floor( scrollLeft / cardWidthForScroll ) * cardWidthForScroll;

                if ( scrollUntilPreviousInCards < this.scrollLeftGutter ) {
                    swiperEl.scrollBy( -( this.cardWidthForScroll * this.cardsToScroll ), 0 );
                } else {
                    const scrollPastTheCurrentCard = scrollLeft - Math.floor( scrollLeft / cardWidthForScroll ) * this.cardWidthForScroll;

                    swiperEl.scrollBy( -( scrollPastTheCurrentCard ), 0 );
                }
            },
            goToNextSlides( ) {
                const swiperEl = this.$refs.swiper;
                const cardWidthForScroll = this.cardWidthForScroll;
                const scrollLeft = swiperEl.scrollLeft;

                const scrollUntilNextInCards = cardWidthForScroll - ( ( scrollLeft / cardWidthForScroll ) - Math.floor( scrollLeft / cardWidthForScroll ) ) * cardWidthForScroll;

                if ( scrollUntilNextInCards < 1 ) {
                    swiperEl.scrollBy( this.cardWidthForScroll * this.cardsToScroll + scrollUntilNextInCards, 0 );
                } else {
                    const scrollPastTheCurrentCard = scrollLeft - Math.floor( scrollLeft / cardWidthForScroll ) * this.cardWidthForScroll;

                    swiperEl.scrollBy( this.cardWidthForScroll - scrollPastTheCurrentCard, 0 );
                }

            },
            setSwiperNavigationDebounced: debounce( function ( ) {
                const swiperEl = this.$refs.swiper;

                if ( swiperEl ) {
                    this.isReachStart = swiperEl.scrollLeft <= this.scrollLeftGutter;
                    this.isReachEnd = ( swiperEl.scrollWidth - swiperEl.scrollLeft - swiperEl.offsetWidth ) < 1;
                }
            }, 50 ),
            setSwiperPaddingDebounced: debounce( function ( ) {
                const swiperEl = this.$refs.swiper;
                this.swiperPaddings = { };

                if ( swiperEl ) {
                    setTimeout( () => {
                        const { cardWidth } = this;
                        const swiperElBounds = swiperEl.getBoundingClientRect();

                        if ( this.center ) {
                            const paddingSize = `${swiperElBounds.width / 2 - cardWidth / 2}px`;
                            this.swiperPaddings = {
                                paddingLeft: paddingSize,
                                paddingRight: paddingSize,
                            };
                        }
                    }, 5 );
                }
            }, 50 ),
        },
        mounted( ) {
            this.setSwiperNavigationDebounced( );
            this.setSwiperPaddingDebounced( );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.setSwiperNavigationDebounced );
            window.removeEventListener( 'resize', this.setSwiperPaddingDebounced );
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-swiper {
        .gst-swiper__content {
            scroll-behavior: smooth;
            overflow-x: scroll;
        }

        .gst-swiper__navigation {
            .gst-btn:disabled {
                opacity: 0.4 !important;
            }
        }
    }
</style>
