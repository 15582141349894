<template>
    <div>
        <h5 class="gst-event-package-offer-not-available__title">
            {{ $t( 'title' ) }}
        </h5>
        <div class="gst-event-package-offer-not-available__content">
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
    import {
        differenceInSeconds as dateUtilsDifferenceInSeconds
    } from '@tenant/app/utils/dateUtils';

    export default {
        name: 'EventStateOnSaleDateFutureWarningCard',
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'messages.eventState.onSaleDateFuture'
        },
        props: {
            event: {
                type: Object,
                default: null
            }
        },
        data( ) {
            return {
                now: new Date( ),
            };
        },
        computed: {
            isEventStartIn24Hours( ) {
                const { now } = this;
                const { onSaleStartDateTimeAt } = this.event;

                return dateUtilsDifferenceInSeconds( onSaleStartDateTimeAt, now,  ) < 24 * 60 * 60;
            },
            isEventInTheFuture( ) {
                const { now } = this;
                const { onSaleStartDateTimeAt } = this.event;

                return dateUtilsDifferenceInSeconds( onSaleStartDateTimeAt, now ) > 0;
            },
            message( ) {
                const { onSaleStartDateTimeAt } = this.event;
                const { now } = this;

                if ( this.isEventStartIn24Hours ) {
                    return this.$t( 'messageWithDateAndTime', {
                        date: this.$options.filters.date( onSaleStartDateTimeAt, 'MMM dd, yyyy' ),
                        time: this.$options.filters.date(
                            new Date( 0, 0, 0, 0, 0, dateUtilsDifferenceInSeconds( onSaleStartDateTimeAt, now ) ),
                            'HH:mm:ss'
                        )
                    } );
                }

                return this.$t( 'messageWithDate', {
                    date: this.$options.filters.date( onSaleStartDateTimeAt, 'MMM dd, yyyy' ),
                } );
            }
        },
        watch: {
            'isEventInTheFuture': {
                handler: function ( value ) {
                    if ( !value ) {
                        window.location.reload( );
                    }
                }
            }
        },
        mounted( ) {
            this._interval = setInterval( ( ) => {
                this.now = new Date( );
            }, 1000 );
        },
        destroyed( ) {
            clearInterval( this._interval );
        }
    };
</script>