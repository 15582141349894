const HIGHLIGHTS_TYPES = {
    HOTEL: 'HOTEL',
    TICKET: 'TICKET',
};


export function getDescriptionForHotel( packageHighlights = [ ] ) {
    const highlight = packageHighlights.find( item => item.type === HIGHLIGHTS_TYPES.HOTEL );

    if ( highlight ) {
        return highlight.description;
    }
}

export function getDescriptionForTicket( packageHighlights = [ ] ) {
    const highlight = packageHighlights.find( item => item.type === HIGHLIGHTS_TYPES.TICKET );

    if ( highlight ) {
        return highlight.description;
    }
}